import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Link,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Sw from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LoginModalCreate from "./modalCreate";
import LoginModalForgot from "./modalForgot";
import Loading from "../../components/Loading";
import api from "../../services/api";
import useAuth from "../../hooks/useAuth";
import { BoxContainer } from "./styles";

const Swal = withReactContent(Sw);

function LoginPage() {
  const { control, handleSubmit } = useForm();
  const user = useAuth();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const captchaRef = useRef<HCaptcha>(null);

  async function onSubmit(form: any) {
    setLoading(true);
    form = {
      ...form,
      token,
    };
    try {
      const { data } = await api.post("/login", form);
      localStorage.setItem("access_token", data?.token?.token);
      user.setUser(data?.user);
      user.setBenoToken(data?.benoToken);
      Swal.fire({
        icon: "success",
        title: "Bem vindo(a)",
        text: data?.user?.name,
        timer: 3000,
        confirmButtonText: "visualizar portal",
      }).then(() => {
        navigate("/");
      });
    } catch (error: any) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Erro",
        html: `${error?.response?.data?.message}`,
      });
    } finally {
      setToken(null);
      setLoading(false);
      captchaRef.current?.resetCaptcha();
    }
  }

  return (
    <>
      <Container maxWidth="lg">
        <BoxContainer>
          <div className="top">
            <p>
              <img src="/assets/images/benoliel-1.png" alt="benoliel" />
            </p>
            <h1>ÁREA DO CLIENTE</h1>
          </div>

          <Grid container spacing={2}>
            <Grid item md={4} xs={1} display={{ xs: "none", md: "block" }} />
            <Grid item xs={12} md={4}>
              <div className="card">
                <div className="titulo">
                  <img src="/assets/images/icone1.png" alt="benoliel" />
                  <div>
                    <b>LOGIN</b>
                    <br />
                    <span>Acesse agora com facilidade</span>
                  </div>
                </div>

                <div className="card-conteudo">
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    className="login"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name="cpf"
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field, fieldState: { error } }) => (
                            <InputMask
                              autoComplete="off"
                              className="w-full"
                              mask="999.999.999-99"
                              disabled={false}
                              {...field}
                            >
                              {
                                // @ts-ignore
                                () => (
                                  <TextField
                                    type="tel"
                                    error={!!error}
                                    className="w-full"
                                    label="CPF"
                                    placeholder="___.___.___-__"
                                    variant="outlined"
                                    autoComplete="username"
                                  />
                                )
                              }
                            </InputMask>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name="password"
                          defaultValue=""
                          rules={{ required: true, minLength: 6 }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              autoComplete="off"
                              error={!!error}
                              className="w-full"
                              label="Senha"
                              variant="outlined"
                              {...field}
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => setShowPassword((s) => !s)}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                        <div
                          style={{
                            textAlign: "right",
                            padding: 5,
                            fontSize: 12,
                          }}
                        >
                          <Link
                            underline="none"
                            onClick={() => setModal2(true)}
                          >
                            Esqueci minha senha
                          </Link>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <HCaptcha
                            sitekey={
                              process.env.REACT_APP_CAPTCHA_KEY as string
                            }
                            onVerify={setToken}
                            onLoad={() => setToken(null)}
                            ref={captchaRef}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          disabled={!token}
                          type="submit"
                          variant="contained"
                          className="beno-button"
                          color="primary"
                          size="large"
                        >
                          {loading ? <Loading width="50" /> : "Entrar"}
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Link underline="none" onClick={() => setModal(true)}>
                          <b style={{ fontSize: 18 }}>Cadastre-se</b>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </div>
            </Grid>
          </Grid>
        </BoxContainer>
      </Container>
      <LoginModalCreate open={modal} setOpen={setModal} />
      <LoginModalForgot open={modal2} setOpen={setModal2} />
    </>
  );
}

export default LoginPage;
