import ContainerStyle from "./styles";

type CustomContainerProps = {
  children: React.ReactNode;
};

function CustomContainer(props: CustomContainerProps) {
  return <ContainerStyle>{props.children}</ContainerStyle>;
}

export default CustomContainer;
