import styled from "styled-components";

const ContainerStyle = styled.div`
  width: 100%;
  padding-bottom: 30px;

  .months {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 3px;

    overflow: auto;

    button {
      display: flex;
      width: 100%;
      max-height: 76px;
      min-width: 150px;
      flex-direction: column;

      border: 1px solid #ccc;
      padding: 10px;
      background-color: #d67b65;
      color: white;
      cursor: pointer;
      border-radius: 10px;

      &:hover,
      &.active {
        background-color: #313443;
      }

      h5 {
        font-size: 20px;
        font-weight: 900;
      }
      h6 {
        font-size: 16px;
      }
    }
  }

  .promos {
    width: 100%;
    border: 1px solid #ccc;
    min-height: 200px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;

    .not-promo {
      width: 100%;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h2 {
        margin-top: 20px;
        text-transform: uppercase;
      }
    }
  }

  .pagamento-aviso {
    width: 100%;
    p {
      text-align: center;
      color: #dc3545;
    }
  }
`;

export default ContainerStyle;
