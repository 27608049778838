import styled from "styled-components";

const ContainerStyle = styled.div`
  width: 100%;

  .welcome {
    display: flex;
    flex-direction: column;
    h3 {
      font-weight: bold;
      color: #313443;
      font-size: 28px;
      padding: 0;
    }
    h2 {
      font-weight: 900;
      font-weight: column;
      color: #727376;
      font-size: 32px;
      padding: 0;
    }
    h6 {
      color: #313443;
      font-weight: 500;
      padding: 0;
      font-size: 16px;
    }
  }
`;

export default ContainerStyle;
