import styled from "styled-components";

const ContainerStyle = styled.div`
  width: 100%;

  @media (min-width: 500px) {
    padding: 30px;
  }

  .total {
    h4 {
      font-size: 24px;
      font-weight: 700;
    }
    h5 {
      color: #96989a;
      font-size: 20px;
      font-weight: 700;
    }
  }
`;

export default ContainerStyle;
