import { useEffect } from "react";
import Sw from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Inputmask from "inputmask";
import useAuth from "../../hooks/useAuth";
import api from "../../services/api";
import { UserProps } from "../../store/user/props";

const Swal = withReactContent(Sw);

function UpdatePhone() {
  const { user, setUser } = useAuth();

  useEffect(() => {
    // condition
    if (
      user?.id &&
      !user?.tags?.includes("NEW_CLIENT") &&
      !user?.tags?.includes("UPDATED_PHONE")
    ) {
      questionPhone();
    }
  }, [user]);

  function questionPhone() {
    Swal.fire({
      icon: "question",
      title: "Atualização",
      html: `Seu telefone <b>${user?.phone}</b><br/>está atualizado?`,
      showCloseButton: true,
      confirmButtonText: "Sim, é o atualizado",
      showDenyButton: true,
      denyButtonText: "Não, quero atualizar!",
      allowOutsideClick: false,
      preConfirm: async () => {
        try {
          const { data } = await api.post(`/user-phone`, {
            isCurrent: true,
          });
          return data;
        } catch (error: any) {
          Swal.fire({
            icon: "error",
            title: "Erro",
            html: `${error?.response?.data?.message}<br/><br/>Em caso de dúvidas, entre em contato.`,
          });
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        success();
      }
      if (result.isDenied) {
        updatePhone();
      }
    });
  }

  function success() {
    setUser({
      ...user,
      tags: [...(user?.tags ?? []), "UPDATED_PHONE"],
    } as UserProps);

    Swal.fire({
      icon: "success",
      title: "Sucesso",
      html: `Telefone atualizado com sucesso!`,
    });
  }

  async function updatePhone() {
    Swal.fire({
      showLoaderOnConfirm: true,
      timerProgressBar: true,
      title: "Atualizar Telefone",
      html: "Informe seu número de telefone",
      inputAttributes: {
        id: "input_phone",
      },
      input: "tel",
      inputPlaceholder: "(__) _____-____",
      confirmButtonText: "Atualizar",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      didOpen: () => {
        Inputmask("(99) 99999-9999").mask(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          document.getElementById("input_phone")!
        );
      },
      inputValidator: (value) => {
        if (!value || value.replace(/[^0-9]/g, "").length < 11) {
          return "Informe o número de telefone";
        }
        return null;
      },

      preConfirm: async (phone) => {
        Swal.getCancelButton()?.setAttribute("disabled", "true");
        try {
          const { data } = await api.post(`/user-phone`, {
            phone,
          });
          return data;
        } catch (error: any) {
          Swal.fire({
            icon: "error",
            title: "Erro",
            html: `${error?.response?.data?.message}<br/><br/>Em caso de dúvidas, entre em contato.`,
          });
        }
      },
    }).then((result) => {
      const { value } = result;
      if (result.isConfirmed && value) {
        success();
      }
    });
  }

  return <div />;
}

export default UpdatePhone;
