import { Route } from "react-router-dom";

import PrivateRouteCheck from "./PrivateRouteCheck";
import InvoicesPage from "../pages/invoices";
import HomePage from "../pages/home";
import PromotionsPage from "../pages/promotions";

const PrivateRoute = (
  <Route element={<PrivateRouteCheck />}>
    <Route path="/" element={<HomePage />} />
    <Route path="/promocoes" element={<PromotionsPage />} />
    <Route path="/faturas" element={<InvoicesPage />} />
  </Route>
);
export default PrivateRoute;
