/* eslint-disable no-plusplus */
/* eslint-disable radix */
import moment from "moment";
import { FieldValues, RegisterOptions } from "react-hook-form";

export const RuleRequired = {
  required: "Este campo é obrigatório",
};

export const RuleEmail: RegisterOptions<FieldValues, string> = {
  ...RuleRequired,
  pattern: {
    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    message: "Digite um endereço de e-mail válido",
  },
};
export const RuleCPF: RegisterOptions = {
  ...RuleRequired,
  pattern: {
    value: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
    message: "Formato do CPF inválido",
  },
  validate: (value) => verifyCPF(value) || "CPF inválido",
};

export const RulePassword: RegisterOptions<FieldValues, string> = {
  ...RuleRequired,
  minLength: {
    value: 6,
    message: "Mínimo de 6 caracteres",
  },
};

export function verifyCPF(cpf: string): boolean {
  cpf = cpf.replace(/[.-]/g, "");

  if (
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  ) {
    return false;
  }

  let sum = 0;
  let remainder;

  for (let i = 1; i <= 9; i++)
    sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.substring(10, 11))) return false;

  return true;
}

export const RuleAge: RegisterOptions<FieldValues, string> = {
  ...RuleRequired,
  validate: (value) => {
    const currentDate = moment();
    const inputDate = moment(value, "DD/MM/YYYY");
    const ageDiff = currentDate.diff(inputDate, "years");
    const maxAge = 110;
    if (!inputDate.isValid()) {
      return "Informe uma data válida";
    }
    if (ageDiff < 18) {
      return "Você deve ter pelo menos 18 anos de idade";
    }
    if (ageDiff > maxAge) {
      return `Você deve ter menos de ${maxAge} anos de idade`;
    }

    return true;
  },
};
