import DialogContentText from "@mui/material/DialogContentText";
import { Button, Grid } from "@mui/material";
import { useEffect } from "react";
import SignupCliente from "./cliente";
import useLogic from "./useLogic";

function Signup({ onClose, open }: any) {
  const props = useLogic({ onClose });

  useEffect(() => {
    props.setClientType(null);
    props.reset({});
  }, [open]);

  return (
    <div>
      <DialogContentText style={{ marginBottom: 15 }}>
        Se você ainda não possui cadastro no <b>PORTAL ONLINE</b>, preencha os
        dados e crie sua conta. Seus dados precisam estar atualizados, em caso
        de duvidas entre em contato.
      </DialogContentText>
      {!props.clientType && (
        <div>
          <div style={{ marginBottom: 10 }}>
            <h3>Você é cliente Benoliel?</h3>
          </div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="info"
                size="large"
                onClick={() => props.setClientType("YES")}
              >
                Sim!
                <br />
                Sou cliente
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="warning"
                size="large"
                onClick={() => props.setClientType("NO")}
              >
                Não!
                <br />
                Quero cadastrar
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
      {!!props.clientType && <SignupCliente {...props} />}
    </div>
  );
}

export default Signup;
