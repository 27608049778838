import {
  Grid,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Button,
  Typography,
} from "@mui/material";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import { DoneOutline } from "@mui/icons-material";
import { PromoInvoice, PromoResponse } from "./type";
import { formatValueToBRL } from "../../utils/common";
import { momentTimeBSB } from "../../utils/moment";

type PromoActiveProps = {
  response: PromoResponse;
  reqTicket: (invoice?: PromoInvoice) => void;
};

function PromoActive({ response, reqTicket }: PromoActiveProps) {
  const { active, invoices, queues, tickets } = response;

  const listInvoice = queues.map((q) => q.invoice);

  const now = momentTimeBSB()
    .format("YYYY-MM-DD")
    .replace(/[^0-9]/g, "");

  const isValid =
    Number(now) >= Number(active.dateStart.replace(/[^0-9]/g, "")) &&
    Number(now) <= Number(active.dateEnd.replace(/[^0-9]/g, ""));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} textAlign="center">
        <p style={{ marginBottom: 5 }}>
          De <b>{moment(active.dateStart).format("DD/MM/YYYY")}</b> a{" "}
          <b>{moment(active.dateEnd).format("DD/MM/YYYY")}</b>.
        </p>
        <p>
          A cada <b>{formatValueToBRL(active.invoiceValue, "R$ ")}</b> em
          compras, um número da sorte!
        </p>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={3}
                  sx={{ backgroundColor: "#313443", color: "white" }}
                >
                  <h3>Últimas compras</h3>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ backgroundColor: "#313443", color: "white" }}
                >
                  Data
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ backgroundColor: "#313443", color: "white" }}
                >
                  Valor
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ backgroundColor: "#313443", color: "white" }}
                >
                  Gerar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!invoices.length && (
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    sem compras neste período
                  </TableCell>
                </TableRow>
              )}
              {invoices.map((invoice) => (
                <TableRow key={invoice.invoiceCode}>
                  <TableCell align="center">
                    {moment(invoice.transactionDate).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell align="center">
                    {formatValueToBRL(invoice.totalValue, "")}
                  </TableCell>
                  <TableCell align="center">
                    {listInvoice.includes(invoice.invoiceCode) ? (
                      <div>
                        <DoneOutline color="success" />
                      </div>
                    ) : (
                      <div>
                        {isValid && invoice.totalValue >= active.invoiceValue && (
                          <LoadingButton
                            size="small"
                            type="button"
                            variant="contained"
                            color="success"
                            onClick={() => reqTicket(invoice)}
                          >
                            Gerar
                          </LoadingButton>
                        )}
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={2}
                  sx={{ backgroundColor: "#313443", color: "white" }}
                >
                  <h3>Meu(s) número(s) da sorte</h3>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ backgroundColor: "#313443", color: "white" }}
                >
                  Data
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ backgroundColor: "#313443", color: "white" }}
                >
                  Número
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!tickets.length && (
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    sem número(s) até o momento
                  </TableCell>
                </TableRow>
              )}
              {tickets.map((ticket) => (
                <TableRow key={ticket.id}>
                  <TableCell align="center">
                    {moment(ticket.createdAt).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell align="center">{ticket.ticket}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} textAlign="center" sx={{ my: 2 }}>
        <Button
          size="medium"
          type="button"
          variant="contained"
          color="info"
          onClick={() => reqTicket()}
          disabled={!isValid}
        >
          Cadastrar Manualmente
        </Button>
        {!isValid && (
          <Typography color="red" fontSize={12} sx={{ mt: 1 }}>
            Aguarde o período da promoção
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default PromoActive;
