import styled from "styled-components";

export const BoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;

  .top {
    text-align: center;
    img {
      max-height: 150px;
      margin-top: 20px;
    }
    h1 {
      font-weight: bold;
      margin-top: 30px;
      margin-bottom: 30px;
      color: #313443;
    }
  }

  .card {
    width: 100%;
    height: 500px;
    border-radius: 10px;
    overflow: hidden;

    .titulo {
      width: 100%;
      background-color: #d67b65;
      padding: 15px;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      img {
        margin-right: 10px;
      }
    }
  }

  .card-conteudo {
    height: 100%;
    padding: 20px;
    background-color: white;
    text-align: center;

    a {
      color: #d67b65;
    }

    .login {
      padding-top: 50px;
    }
  }
`;
