import styled from "styled-components";

const ContainerStyle = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  min-height: 200px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
`;

export default ContainerStyle;
