import { Grid, Button, Box } from "@mui/material/";
import moment from "moment";
import { useEffect, useState } from "react";
// import Sw from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
import Loading from "../../components/Loading";
import useAuth from "../../hooks/useAuth";
import ContainerStyle from "./styles";
import api from "../../services/api";
import FaturasTable from "../../components/FaturasTable";
import { CalculeValue } from "../../core/loja";
// import BoletosTable from "../../components/BoletosTable";
import Layout from "../../components/Layout";
import CustomContainer from "../../components/Container";

// const Swal = withReactContent(Sw);

const listMonths0 = Array.from({ length: 6 }, (_, i) => i + 1).map(
  (_, index) => ({
    title: moment().add(index, "months").format("MMMM"),
    subtitle: Number(moment().add(index, "months").format("YYYY")),
    index: index + 2,
    mesAno: moment().add(index, "months").format("YYYY-MM"),
  })
);
const listMonths = [
  { title: "Em aberto", subtitle: "Fatura(s)", index: 1, mesAno: "" },
  ...listMonths0,
];

function InvoicesPage() {
  const { user, benoToken } = useAuth();
  const [monthActive, setMonthActive] = useState(0);
  const [loading, setLoading] = useState(true);
  const [faturas, setFaturas] = useState<any>([]);

  useEffect(() => {
    if (user?.id) {
      gets();
    }
  }, [user]);

  async function gets() {
    await getFaturas();
    // await getBoletos();
    setLoading(false);
  }

  async function getFaturas() {
    const send = {
      benoToken,
      startExpiredDate: moment()
        .subtract(3, "months")
        .format("YYYY-MM-DDT00:00:00"),
      endExpiredDate: moment().add(7, "months").format("YYYY-MM-DDT00:00:00"),
    };
    const { data } = await api.post("/faturas", send);
    const newList = data?.items.map((m: any) => ({
      ...m,
      ...CalculeValue(m),
    }));

    const itemsOpeneds = data?.items?.filter(
      (f: any) =>
        moment.duration(moment(f.expiredDate).diff(moment())).asDays() <= 0
    );
    setMonthActive(itemsOpeneds?.length ? 1 : 2);
    setFaturas(newList);
  }

  // async function getBoletos() {
  //   const send = { benoToken };
  //   const { data } = await api.post("/boletos", send);
  //   const newList = data?.items;
  //   setBoletos(newList);
  // }

  const itemsOpeneds = faturas?.filter(
    (f: any) =>
      moment.duration(moment(f.expiredDate).diff(moment())).asDays() <= 0
  );

  const mActive =
    listMonths.find((f) => f.index === monthActive) ?? listMonths[0];

  const items = faturas?.filter((f: any) => {
    if (
      mActive.index === 1 &&
      moment.duration(moment(f.expiredDate).diff(moment())).asDays() >= 1
    ) {
      return false;
    }

    if (
      mActive.index >= 2 &&
      mActive.mesAno !== moment(f.expiredDate).format("YYYY-MM")
    ) {
      return false;
    }
    return f;
  });

  const total = items?.length
    ? items?.map((m: any) => m.total)?.reduce((a: number, b: number) => a + b)
    : 0;

  // async function getPdf(boleto: any) {
  //   let url = "";
  //   Swal.fire({
  //     icon: "info",
  //     title: "Aguarde",
  //     html: "Estamos carregando seu boleto",
  //     allowOutsideClick: () => !Swal.isLoading(),
  //     didOpen: async () => {
  //       Swal.showLoading();
  //       const send = {
  //         benoToken,
  //         boleto,
  //       };
  //       try {
  //         const { data } = await api.post("/boletos/pdf", send, {
  //           responseType: "blob",
  //         });
  //         const file = new Blob([data], { type: "application/pdf" });
  //         const fileURL = URL.createObjectURL(file);
  //         url = fileURL;
  //       } catch (error) {
  //         Swal.fire("Error", JSON.stringify(error), "error");
  //       } finally {
  //         Swal.close();
  //       }
  //     },
  //     willClose: () => {
  //       if (url) {
  //         Swal.fire({
  //           icon: "success",
  //           title: "Sucesso",
  //           text: "Boleto gerado com sucesso, clique no link abaixo",
  //           backdrop: true,
  //           showConfirmButton: false,
  //           showCloseButton: true,
  //           footer: `<a style="text-decoration:none" class="swal2-confirm swal2-styled"  target="_blank" href="${url}">Visualizar Boleto</a>`,
  //         });
  //       }
  //     },
  //   }).then((data) => console.log(data, url));
  // }

  return (
    <Layout>
      <ContainerStyle>
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            <BoletosTable items={boletos} getPdf={getPdf} />
          </Grid> */}

          <Grid item xs={12} className="months">
            {listMonths
              ?.filter((f) => {
                if (f.index === 1 && !itemsOpeneds?.length) {
                  return false;
                }
                if (f.index === 7 && itemsOpeneds?.length) {
                  return false;
                }
                return f;
              })
              .map((item, index) => (
                <Button
                  key={`month-${index}`}
                  onClick={() => setMonthActive(item.index)}
                  className={`month-item ${
                    monthActive === item.index ? "active" : undefined
                  }`}
                >
                  <h5>{item.title}</h5>
                  <h6>{item.subtitle}</h6>
                </Button>
              ))}
          </Grid>

          <Grid
            item
            xs={12}
            textAlign="center"
            justifyContent="center"
            justifyItems="center"
            alignContent="center"
          >
            <CustomContainer>
              {loading && (
                <Box>
                  <Loading width="500" />
                </Box>
              )}

              <FaturasTable
                items={items}
                invoiceOpen={Boolean(mActive.index === -1)}
                total={total}
              />

              {mActive?.index === 1 && !!items?.length && (
                <Box m={2}>
                  Para pagamento de valores parciais, entre em contato com nosso
                  departamento de cobrança pelo número (96) 99126-6379.
                </Box>
              )}

              {!loading && !items?.length && (
                <div className="not-invoice">
                  <img
                    src="/assets/images/sem-fatura.png"
                    alt="sem fatura"
                    width={93}
                  />
                  <h2>
                    VOCÊ NÃO POSSUI FATURAS {mActive.index >= 2 && "EM"}{" "}
                    {mActive?.title.toUpperCase()}!
                  </h2>
                </div>
              )}
            </CustomContainer>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} className="pagamento-aviso">
            <p>
              Em breve o pagamento da fatura, poderá ser realizado nesse canal.
            </p>
          </Grid>
        </Grid>
      </ContainerStyle>
    </Layout>
  );
}

export default InvoicesPage;
