import { useState } from "react";
import Sw from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../services/api";
import useAuth from "../../hooks/useAuth";
import { PromoInvoice, PromoResponse } from "./type";

const Swal = withReactContent(Sw);

const useLogic = () => {
  const { benoToken, user } = useAuth();
  const [active, setActive] = useState<string>("DIA_DOS_NAMORADOS_2024");
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<PromoResponse | null>(null);
  const promotions = [
    {
      title: "2024",
      subtitle: "Dia dos Namorados",
      reference: "DIA_DOS_NAMORADOS_2024",
    },
    {
      title: "Outras",
      subtitle: "Promoções",
      reference: "OLD",
    },
  ];

  async function getDetails() {
    setLoading(true);
    try {
      const { data } = await api.post(`/promo/get-user-detail`, { benoToken });
      setResponse(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function reqTicket(invoice?: PromoInvoice) {
    Swal.fire({
      showLoaderOnConfirm: true,
      timerProgressBar: true,
      title: invoice ? "Solicitando" : "Cadastrar",
      html: invoice
        ? `Você está solicitando números da sorte<br/>Compra <b>${invoice?.invoiceCode}</b>`
        : "Informe o número do cupom",

      imageUrl: !invoice ? "/exemplo-cupom.png" : undefined,
      imageWidth: "90%",
      input: !invoice ? "tel" : undefined,
      inputPlaceholder: "Número do cupom",
      confirmButtonText: "Cadastrar",
      inputValidator: (value) => {
        if (!value || value.length < 4) {
          return "Informe o número do cupom";
        }
        return null;
      },
      allowOutsideClick: () => !Swal.isLoading(),
      didOpen: async () => {
        if (invoice) {
          Swal.clickConfirm();
        }
      },
      preConfirm: async (code) => {
        try {
          const { data } = await api.post(`/promo/save-invoice`, {
            benoToken,
            invoice: invoice ? invoice.invoiceCode : Number(code),
            code: user?.code,
          });
          return data;
        } catch (error: any) {
          Swal.fire({
            icon: "error",
            title: "Erro",
            html: `${error?.response?.data?.message}<br/><br/>Em caso de dúvidas, entre em contato.`,
          });
        }
      },
    }).then((result) => {
      const { value } = result;
      if (value?.invoice) {
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          html: `Pedido enviado com sucesso, aguarde seus números serão gerados em breve!`,
        });
        const newObj = {
          ...response,
          queues: [...(response?.queues ?? []), value],
        } as PromoResponse;
        setResponse(newObj);
      }
    });
  }

  return {
    promotions,
    loading,
    active,
    response,
    reqTicket,
    setActive,
    getDetails,
  };
};

export default useLogic;
