import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import CustomContainer from "../../components/Container";
import Layout from "../../components/Layout";
import UpdatePhone from "./updatePhone";

function HomePage() {
  return (
    <Layout>
      <UpdatePhone />
      <CustomContainer>
        <Grid container justifyContent="center" spacing={2} sx={{ mt: 4 }}>
          <Grid item xs={12} textAlign="center">
            <h3>Escolha uma das opções</h3>
          </Grid>
          <Grid item>
            <Link to="/promocoes">
              <Button
                type="button"
                variant="contained"
                size="large"
                sx={{ minWidth: 130 }}
              >
                Promoções
              </Button>
            </Link>
          </Grid>
          <Grid item>
            <Link to="/faturas">
              <Button
                type="button"
                variant="contained"
                size="large"
                sx={{ minWidth: 130 }}
              >
                Faturas
              </Button>
            </Link>
          </Grid>
        </Grid>
      </CustomContainer>
    </Layout>
  );
}

export default HomePage;
