import { ActionProps, UserProps } from "./props";

export function setUser(user: UserProps): ActionProps {
  return {
    type: "SET_USER",
    user,
  };
}

export function setBenoToken(benoToken: string): ActionProps {
  return {
    type: "SET_USER_BENOTOKEN",
    benoToken,
  };
}
