import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import BootstrapDialogTitle from "../../components/Dialog/title";
import Transition from "../../components/Dialog/transation";
import Signup from "../../components/Signup";

function LoginModalCreate({ open, setOpen }: any) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
      >
        <BootstrapDialogTitle id="modal-cadastrar" onClose={handleClose}>
          Cadastre-se
        </BootstrapDialogTitle>
        <DialogContent>
          <Signup onClose={handleClose} open={open} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default LoginModalCreate;
