/* eslint-disable default-param-last */
import { UserReduceProps, ActionProps } from "./props";

const initState: UserReduceProps = {};

export default function reducer(state = initState, action: ActionProps) {
  if (action.type === "SET_USER") {
    return {
      ...state,
      user: action.user,
    };
  }
  if (action.type === "SET_USER_BENOTOKEN") {
    return {
      ...state,
      benoToken: action.benoToken,
    };
  }

  return state;
}
