import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { Link, useLocation } from "react-router-dom";
import ContainerStyle from "./styles";
import { DataMenuItems } from "../../data/menu";
import useAuth from "../../hooks/useAuth";

function LayoutHeader() {
  const { user, logout } = useAuth();
  const location = useLocation();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const path = location.pathname;

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 2, borderBottom: "1px solid #cccccc40", mb: 2 }}
    >
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ display: { xs: "none", md: "flex" }, mr: 2 }}>
              <img
                src="/assets/images/logo-2019.png"
                alt="Benoliel"
                style={{ maxWidth: 50 }}
              />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {DataMenuItems.map((page) => (
                  <MenuItem
                    component={Link}
                    key={page.title}
                    to={page.href}
                    sx={{
                      color: page.href === path ? "#d67b65" : "#666",
                    }}
                  >
                    <Typography textAlign="center">{page.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
              <img
                src="/assets/images/logo-2019.png"
                alt="Benoliel"
                style={{ maxWidth: 20 }}
              />
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                fontSize: 12,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Benoliel
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {DataMenuItems.map((page) => (
                <MenuItem
                  component={Link}
                  to={page.href}
                  key={page.title}
                  sx={{
                    my: 2,
                    color: page.href === path ? "#d67b65" : "white",
                    fontWeight: page.href === path ? 600 : 300,
                    display: "block",
                  }}
                >
                  {page.title}
                </MenuItem>
              ))}
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                minWidth: 100,
                textAlign: "right",
              }}
            >
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={logout}>
                  <Typography textAlign="center">Sair</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <ContainerStyle>
        <Grid
          container
          mt={2}
          pb={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} md={10}>
            <div className="welcome">
              <h3>Bem vindo(a)</h3>
              <h2>{user?.name}</h2>
              <h6>Cliente: {user?.code}</h6>
            </div>
          </Grid>
          <Grid item xs={12} md={2} textAlign="right">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={logout}
            >
              sair
            </Button>
          </Grid>
        </Grid>
      </ContainerStyle>
    </Container>
  );
}

export default LayoutHeader;
