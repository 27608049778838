import React from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { Loja, real } from "../../core/loja";
import { StyledTableCell, StyledTableRow } from "./custom";
import ContainerStyle from "./style";

function FaturasTable({ items, total }: any) {
  if (!items?.length) {
    return <div />;
  }
  return (
    <ContainerStyle>
      <Grid
        container
        mb={5}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} textAlign="right" className="total">
          <h4>TOTAL DA FATURA</h4>
          <h5>{real.format(total)}</h5>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Data</StyledTableCell>
              <StyledTableCell>Vencimento</StyledTableCell>
              <StyledTableCell>Loja</StyledTableCell>
              <StyledTableCell>Doc</StyledTableCell>
              <StyledTableCell>Parcela</StyledTableCell>
              <StyledTableCell>Valor</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((row: any, index: number) => {
              return (
                <StyledTableRow key={`row-${index}`}>
                  <StyledTableCell component="th" scope="row">
                    {moment(row.issueDate).format("DD/MM/YYYY")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {moment(row.expiredDate).format("DD/MM/YYYY")}
                  </StyledTableCell>
                  <StyledTableCell>{Loja(row.branchCode)}</StyledTableCell>
                  <StyledTableCell>{row.receivableCode}</StyledTableCell>
                  <StyledTableCell>{row.installmentCode}</StyledTableCell>
                  <StyledTableCell>{row.component}</StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ContainerStyle>
  );
}

export default React.memo(FaturasTable);
