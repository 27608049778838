/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Button, Grid, TextField } from "@mui/material";
import InputMask from "react-input-mask";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Controller, useForm } from "react-hook-form";
import Sw from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../services/api";
import Loading from "../../components/Loading";
import BootstrapDialogTitle from "../../components/Dialog/title";
import Transition from "../../components/Dialog/transation";

const Swal = withReactContent(Sw);

function LoginModalForgot({ open, setOpen }: any) {
  const captchaRef = React.useRef<HCaptcha>(null);
  const [token, setToken] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { reset, control, handleSubmit } = useForm();

  const handleClose = () => {
    setOpen(false);
  };

  async function onSubmit(form: any) {
    form = {
      ...form,
      token,
    };

    setLoading(true);

    try {
      const { data } = await api.post("/forgot-account", form);
      console.log(data);
      Swal.fire({
        icon: "success",
        title: "Verifique seu email",
        html: `Caso o cadastro seja encontrado, um email será enviado para redefinição de senha, verifique sua caixa de entrada ou spam.`,
      }).then(() => {
        reset();
        setOpen(false);
      });
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: `${error?.response?.data?.message}<br/>Em caso de dúvidas, entre em contato.`,
      });
    } finally {
      setToken(null);
      captchaRef.current?.resetCaptcha();
      setLoading(false);
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
      >
        <BootstrapDialogTitle id="modal-cadastrar" onClose={handleClose}>
          Esqueci minha senha
        </BootstrapDialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: 15 }}>
            Informe seu CPF e será enviado um e-mail com um link para redefinir
            sua senha.
          </DialogContentText>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item md={2} />
              <Grid item xs={12} md={8}>
                <Controller
                  control={control}
                  name="cpf"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <InputMask
                      className="w-full"
                      mask="999.999.999-99"
                      disabled={false}
                      {...field}
                    >
                      {
                        // @ts-ignore
                        () => (
                          <TextField
                            error={!!error}
                            className="w-full"
                            label="CPF"
                            placeholder="___.___.___-__"
                            variant="outlined"
                          />
                        )
                      }
                    </InputMask>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <HCaptcha
                    sitekey={process.env.REACT_APP_CAPTCHA_KEY as string}
                    onVerify={setToken}
                    ref={captchaRef}
                    onLoad={() => setToken(null)}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!token || loading}
                    className="beno-button"
                  >
                    {loading ? <Loading width="50" /> : "Redefinir senha"}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default LoginModalForgot;
