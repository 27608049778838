import React, { useState } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { FieldValues, useForm } from "react-hook-form";
import Sw from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";
import api from "../../services/api";

const Swal = withReactContent(Sw);

const useLogic = ({ onClose }: any) => {
  const captchaRef = React.useRef<HCaptcha>(null);
  const [render, setRender] = useState(0);
  const [token, setToken] = React.useState<string | null>(null);
  const [check, setCheck] = React.useState<boolean>(false);
  const [clientType, setClientType] = React.useState<"YES" | "NO" | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { reset, control, watch, setValue, handleSubmit } = useForm();

  async function onSubmit(form: FieldValues) {
    form = {
      ...form,
      dateOfBirth: moment(form.dateOfBirth, "DD/MM/YYYY").format("YYYY-MM-DD"),
      token,
    };

    setLoading(true);

    try {
      const { data } = await api.post("/create-account", form);
      sessionStorage.setItem("benoToken", data?.benoToken);
      Swal.fire({
        icon: "success",
        title: "Sucesso",
        text: "Usuário Cadastrado com Sucesso",
        html: `Foi enviado um email para <b>${form.email}</b> para definição de senha, verifique sua caixa de entrada ou spam.`,
      }).then(() => {
        reset();
        setCheck(false);
        onClose();
      });
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: `${error?.response?.data?.message}<br/><br/>Em caso de dúvidas, entre em contato.`,
      });
    } finally {
      setToken(null);
      captchaRef.current?.resetCaptcha();
      setLoading(false);
    }
  }

  function errorSubmit(error: any) {
    console.error(error, render);
    setRender((n) => n + 1);
  }

  return {
    check,
    loading,
    token,
    handleSubmit,
    onSubmit,
    errorSubmit,
    control,
    setCheck,
    setToken,
    captchaRef,
    clientType,
    setClientType,
    reset,
    watch,
    setValue,
  };
};

export default useLogic;
