import styled from "styled-components";

type ContentProps = {
  width?: string;
};

export const Content = styled.div<ContentProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: ${(props) => props?.width ?? 200}px;
  }
`;
