/* eslint-disable no-promise-executor-return */
import axios from "axios";

export async function getAddress(cep: string) {
  const url = `https://viacep.com.br/ws/${cep.replace(/[^0-9]/g, "")}/json/`;
  try {
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    return null;
  }
} // getEndereco

export function formatValueToBRL(value: number, prefix = "") {
  let valueNumber = value;
  if (Number.isNaN(valueNumber)) {
    valueNumber = 0;
  }

  let formattedValue = (Math.floor(valueNumber * 100) / 100).toLocaleString(
    "pt-BR",
    {
      minimumFractionDigits: 2,
    }
  );
  const splitValue = formattedValue.split(",");

  if (splitValue[1].length >= 3) {
    formattedValue = `${splitValue[0]},${splitValue[1].slice(0, 2)}`;
  }

  return `${prefix}${formattedValue}`;
}

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export function formatDate(date: string): string {
  const parts = date.split("/");
  const newDate = new Date(
    Number(parts[2]),
    Number(parts[1]) - 1,
    Number(parts[0])
  );
  const year = newDate.getFullYear();
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
  const day = newDate.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}
