import { Box, Button, Grid } from "@mui/material";

import { useEffect } from "react";
import Layout from "../../components/Layout";
import ContainerStyle from "./styles";
import useLogic from "./useLogic";
import Loading from "../../components/Loading";
import PromoActive from "./active";
import CustomContainer from "../../components/Container";

function PromotionsPage() {
  const {
    response,
    promotions,
    loading,
    active,
    reqTicket,
    setActive,
    getDetails,
  } = useLogic();

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <Layout>
      <ContainerStyle>
        <Grid item xs={12} className="months">
          {promotions.map((item, index) => (
            <Button
              key={`month-${index}`}
              onClick={() => setActive(item.reference)}
              className={`month-item ${
                item.reference === active ? "active" : undefined
              }`}
            >
              <h5>{item.title}</h5>
              <h6>{item.subtitle}</h6>
            </Button>
          ))}
        </Grid>
        <CustomContainer>
          {loading && (
            <Box>
              <Loading width="500" />
            </Box>
          )}

          {active === response?.active.reference && (
            <PromoActive response={response} reqTicket={reqTicket} />
          )}

          {!loading && active !== response?.active.reference && (
            <div className="not-promo">
              <img
                src="/assets/images/sem-fatura.png"
                alt="sem fatura"
                width={93}
              />
              <h2>SEM INFORMAÇÕES PARA ESSA ESCOLHA!</h2>
            </div>
          )}
        </CustomContainer>
      </ContainerStyle>
    </Layout>
  );
}

export default PromotionsPage;
