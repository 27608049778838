import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import LoginPage from "../pages/login";
import ResetPasswordPage from "../pages/reset-password";
import PrivateRoute from "./PrivateRoute";

function MainRoutes() {
  return (
    <Routes>
      <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
      <Route path="/login" element={<LoginPage />} />
      {PrivateRoute}
      <Route path="/" element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default MainRoutes;
