import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserProps } from "../store/user/props";
import { ReducersProps } from "../store/props";
import {
  setUser as upUSer,
  setBenoToken as upBenoToken,
} from "../store/user/action";
import api from "../services/api";

const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, benoToken } = useSelector((state: ReducersProps) => state.user);
  const token = localStorage.getItem("access_token") ?? null;

  useEffect(() => {
    if (!user?.id && token) {
      getUser();
    }
  }, []);

  function setUser(u: UserProps) {
    dispatch(upUSer(u));
  }
  function setBenoToken(b: string) {
    dispatch(upBenoToken(b));
  }

  async function getUser() {
    try {
      const { data } = await api.get("/user-data");
      setUser(data?.user);
      setBenoToken(data?.benoToken);
    } catch (e) {
      localStorage.clear();
      navigate("/login");
    }
  }

  async function logout() {
    await api.post("/logout");
    localStorage.clear();
    navigate("/login");
  }

  const isAuth = Boolean(user?.id || token);

  return { user, benoToken, setUser, setBenoToken, isAuth, logout };
};

export default useAuth;
