import { Button, Checkbox, Grid, TextField } from "@mui/material";
import InputMask from "react-input-mask";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Controller } from "react-hook-form";
import Fancybox from "../../utils/fancybox";
import Loading from "../Loading";
import { RuleAge, RuleCPF, RuleEmail } from "../../utils/validation";
import { getAddress } from "../../utils/common";

function SignupCliente({
  check,
  loading,
  token,
  handleSubmit,
  onSubmit,
  errorSubmit,
  control,
  setCheck,
  setToken,
  captchaRef,
  clientType,
  watch,
  setValue,
}: any) {
  const notClient = clientType === "NO";

  return (
    <form onSubmit={handleSubmit(onSubmit, errorSubmit)} autoComplete="off">
      <Grid container spacing={2}>
        {notClient && (
          <>
            <Grid item xs={12} md={7}>
              <Controller
                control={control}
                name="name"
                defaultValue=""
                rules={{ required: true, minLength: 10 }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    error={!!error}
                    type="text"
                    className="w-full"
                    label="Nome Completo"
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Controller
                control={control}
                name="phone"
                defaultValue=""
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <InputMask
                    className="w-full"
                    mask="(99) 99999-9999"
                    disabled={false}
                    {...field}
                  >
                    {
                      // @ts-ignore
                      () => (
                        <TextField
                          error={!!error}
                          className="w-full"
                          label="Telefone"
                          placeholder="(__) _____-____"
                          variant="outlined"
                        />
                      )
                    }
                  </InputMask>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="rg"
                defaultValue=""
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    error={!!error}
                    type="tel"
                    className="w-full"
                    label="RG (número)"
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="rgFederalAgency"
                defaultValue=""
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    error={!!error}
                    type="text"
                    className="w-full"
                    label="Orgão emissor do RG"
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={7}>
          <Controller
            control={control}
            name="cpf"
            defaultValue=""
            rules={RuleCPF}
            render={({ field, fieldState: { error } }) => (
              <InputMask
                className="w-full"
                mask="999.999.999-99"
                disabled={false}
                {...field}
              >
                {
                  // @ts-ignore
                  () => (
                    <TextField
                      error={!!error}
                      className="w-full"
                      label="CPF"
                      placeholder="___.___.___-__"
                      variant="outlined"
                    />
                  )
                }
              </InputMask>
            )}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Controller
            control={control}
            name="dateOfBirth"
            defaultValue=""
            rules={RuleAge}
            render={({ field, fieldState: { error } }) => (
              <InputMask
                className="w-full"
                mask="99/99/9999"
                disabled={false}
                {...field}
              >
                {
                  // @ts-ignore
                  () => (
                    <TextField
                      error={!!error}
                      className="w-full"
                      label="Data de Nascimento"
                      placeholder="__/__/____"
                      variant="outlined"
                    />
                  )
                }
              </InputMask>
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            control={control}
            name="oldEmail"
            defaultValue=""
            rules={RuleEmail}
            render={({ field, fieldState: { error } }) => (
              <TextField
                error={!!error}
                type="email"
                className="w-full"
                label="Email"
                variant="outlined"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            control={control}
            name="email"
            defaultValue=""
            rules={{
              required: true,
              validate: (value) => {
                if (value !== watch("oldEmail")) {
                  return "Emails não são iguais";
                }
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                error={!!error}
                type="email"
                className="w-full"
                label="Confirmar Email"
                variant="outlined"
                {...field}
              />
            )}
          />
        </Grid>

        {notClient && (
          <>
            <Grid item xs={12} textAlign="center">
              <h3>Endereço</h3>
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="cep"
                defaultValue=""
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <InputMask
                    className="w-full"
                    mask="99.999-999"
                    disabled={false}
                    {...field}
                    onBlur={async (event) => {
                      const value = event.target.value.replace(/[^0-9]/g, "");
                      if (value.length >= 8) {
                        const address = await getAddress(value);
                        if (address?.logradouro) {
                          setValue("neighborhood", address?.bairro);
                          setValue("address", address?.logradouro);
                          document.getElementById("address_number")?.focus();
                        }
                      }
                    }}
                  >
                    {
                      // @ts-ignore
                      () => (
                        <TextField
                          error={!!error}
                          className="w-full"
                          label="CEP"
                          placeholder="__.___-___"
                          variant="outlined"
                        />
                      )
                    }
                  </InputMask>
                )}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <Controller
                control={control}
                name="neighborhood"
                defaultValue=""
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    error={!!error}
                    type="text"
                    className="w-full"
                    label="Bairro"
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={9}>
              <Controller
                control={control}
                name="address"
                defaultValue=""
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    error={!!error}
                    type="text"
                    className="w-full"
                    label="Endereço"
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={control}
                name="number"
                defaultValue=""
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="address_number"
                    error={!!error}
                    type="tel"
                    className="w-full"
                    label="N."
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Checkbox onChange={(e) => setCheck(e.target.checked)} />
          li e concordo com o{" "}
          <Fancybox options={{ infinite: false }}>
            <a data-fancybox data-src="/assets/termo-de-uso.pdf">
              <b>termo de uso.</b>
            </a>
          </Fancybox>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <HCaptcha
              sitekey={process.env.REACT_APP_CAPTCHA_KEY as string}
              onVerify={setToken}
              ref={captchaRef}
              onLoad={() => setToken(null)}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={!check || !token || loading}
              className="beno-button"
            >
              {loading ? <Loading width="50" /> : "Cadastrar Conta"}
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

export default SignupCliente;
