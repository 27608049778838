import { Content } from "./style";

type LoadingProps = {
  width?: string;
};

function Loading({ width }: LoadingProps) {
  return (
    <Content width={width}>
      <img src="/assets/images/benoliel.gif" alt="loading" />
    </Content>
  );
}

export default Loading;
