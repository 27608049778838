import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Sw from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loading from "../../components/Loading";
import api from "../../services/api";
import { BoxContainer } from "./styles";

const Swal = withReactContent(Sw);

function ResetPasswordPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { token } = params;
  const { control, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [ok, setOk] = useState(false);

  useEffect(() => {
    checkToken();
  }, []);

  async function checkToken() {
    setLoading(true);
    try {
      await api.post("/reset-password/check", { token });
      setOk(true);
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: `${error?.response?.data?.message}`,
      });
    } finally {
      setLoading(false);
    }
  }

  async function onSubmit(form: any) {
    if (form.password !== form.confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: `Senhas diferentes!`,
      });
      return;
    }
    setLoading(true);
    form = {
      password: form.password,
      token,
    };

    try {
      await api.post("/reset-password", form);
      Swal.fire({
        icon: "success",
        title: "Sucesso",
        text: "Sua senha foi redefinida",
        confirmButtonText: "Ir para login",
      }).then(() => navigate("/"));
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: `${error?.response?.data?.message}`,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container maxWidth="lg">
      <BoxContainer>
        <div className="top" style={{ marginBottom: 20 }}>
          <p>
            <img src="/assets/images/benoliel-1.png" alt="benoliel" />
          </p>
        </div>

        <Grid container spacing={2}>
          <Grid item md={4} />
          <Grid item xs={12} md={4}>
            <div className="card">
              <div className="titulo">
                <img src="/assets/images/icone1.png" alt="benoliel" />
                <div>
                  <b>Redefinir Senha</b>
                  <br />
                  <span>Informe sua nova senha</span>
                </div>
              </div>

              <div className="card-conteudo">
                {loading && <Loading />}
                {!loading && !ok && (
                  <Box mt={5}>
                    <Alert severity="error">Token inválido</Alert>
                  </Box>
                )}
                {ok && (
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    className="login"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name="password"
                          defaultValue=""
                          rules={{ required: true, minLength: 6 }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              error={!!error}
                              className="w-full"
                              label="Senha"
                              autoComplete="current-password"
                              variant="outlined"
                              {...field}
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => setShowPassword((s) => !s)}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name="confirmPassword"
                          defaultValue=""
                          rules={{ required: true, minLength: 6 }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              error={!!error}
                              className="w-full"
                              label="Confirmar Senha"
                              autoComplete="current-password"
                              variant="outlined"
                              {...field}
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => setShowPassword((s) => !s)}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                        <Typography
                          mt={2}
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          * mínimo de 6 caracteres
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          disabled={loading}
                          type="submit"
                          variant="contained"
                          className="beno-button"
                        >
                          {loading ? <Loading width="50" /> : "Redefinir senha"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </BoxContainer>
    </Container>
  );
}

export default ResetPasswordPage;
