import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import moment from "moment";
import "moment/locale/pt-br";
import { ThemeProvider } from "@mui/material/styles";
import MainRoutes from "./routes";
import store from "./store";
import theme from "./theme";

moment.locale("pt-BR");

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <MainRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
