import moment from "moment";

export const real = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export function Loja(codigo: number) {
  if (codigo === 2) {
    return "002 - Benoliel Muca";
  }
  if (codigo === 300) {
    return "300 - Benoliel Trem";
  }
  return "003 - Benoliel Baby";
}

export function Juros(d: string, valor: number) {
  const data = moment(d).format("YYYY-MM-DD");
  const juros2 = (valor * 2) / 100; // juros de 2% ao mes;
  const juros02 = (valor * 0.25) / 100; // juros de 0,2% ao dia;
  const hoje = moment().format("YYYY-MM-DD");
  let juros = 0;
  if (data < hoje) {
    const dias = moment.duration(moment(data).diff(moment())).asDays() * -1;
    if (dias >= 6) {
      juros += juros2; // adicionando juros do m?s
      juros += juros02 * dias; // adicionando juros dia;
    }
  }
  return juros;
} // Juros

export function CalculeValue(row: any) {
  const dias =
    moment.duration(moment(row?.expiredDate).diff(moment())).asDays() * -1;
  const invoiceOpen = Boolean(dias >= 6);

  const juros = invoiceOpen ? Juros(row.expiredDate, row.installmentValue) : 0;
  const total = row.installmentValue + juros;

  const sup = invoiceOpen ? (
    <sup style={{ color: "#dc3545" }}>
      +{real.format(juros).replace("R$", "")}
    </sup>
  ) : undefined;

  const component = (
    <div>
      {real.format(row.installmentValue).replace("R$", "")} {sup}
    </div>
  );

  return { total, juros, component };
}
