import { Container } from "@mui/material";
import LayoutHeader from "./header";

type LayoutProps = {
  children: React.ReactNode;
};

function Layout(props: LayoutProps) {
  return (
    <>
      <LayoutHeader />
      <Container maxWidth="lg">{props.children}</Container>
    </>
  );
}

export default Layout;
