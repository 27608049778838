import moment, { Moment } from "moment";
import "moment-timezone";
import "moment/locale/pt-br";

moment.locale("pt-br");
const timeZone = "America/Sao_Paulo";

export function momentTimeBSB(): Moment {
  const now = moment().tz(timeZone);
  return now;
}

export default moment;
